import { EditableStatus, ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import BeforeAfter from './before-after';
import i18next from '@/i18n';

export enum BeforeAfterImagesActions {
  LOAD_LIST = 'LOAD_LIST',

  /**
   * Edit
   */
  LOAD_EDITABLE = 'LOAD_EDITABLE',
  SAVE_EDITABLE = 'SAVE_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  DELETE = 'DELETE',
  RESTORE = 'RESTORE',
  BULK_DELETE = 'BULK_DELETE',
  BULK_RESTORE = 'BULK_RESTORE',
}

export enum BeforeAfterImagesMutations {
  SET_LIST = 'SET_LIST',
  SET_LIST_PAGINATION = 'SET_LIST_PAGINATION',
  SET_LIST_STATUS = 'SET_LIST_STATUS',

  /**
   * Edit
   */
  SET_EDITABLE = 'SET_EDITABLE',
  UPDATE_EDITABLE = 'UPDATE_EDITABLE',
  SET_EDITABLE_STATUS = 'SET_EDITABLE_STATUS',
}

export enum BeforeAfterImagesStatus {
  Inactive,
  Active,
}

export const BeforeAfterImagesStatuses = {
  [BeforeAfterImagesStatus.Inactive]: i18next.t('Inactive'),
  [BeforeAfterImagesStatus.Active]: i18next.t('Active'),
};

export interface BeforeAfterImagesState {
  /**
   * List
   */
  status: ListStatus;
  list: BeforeAfter[];
  listPagination: Pagination;

  /**
   * Edit
   */
  editable?: BeforeAfter;
  initialEditable?: BeforeAfter;
  editableStatus: EditableStatus;
}
