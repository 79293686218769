
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';

import Autocomplete, { AutocompleteOption } from '@/components/controls/autocomplete.vue';
import Taxonomy from '@/store/models/taxonomy';
import { BeforeAfterImagesActions, BeforeAfterImagesStatus } from '@/store/modules/before-after-images/types';
import BeforeAfterImage from '@/store/modules/before-after-images/before-after-image';
import ProductRelationship from '@/store/modules/products/product-relationship';

import NotificationBanner from '@/components/blocks/notification-banner.vue';
import FormInput from '@/components/controls/form-input.vue';
import ImageUploadControl from '@/components/controls/image-upload.vue';
import Publish from '@/components/forms/publish.vue';
import PracticeSelectionControl from '@/components/controls/practice-selection.vue';
import ProductSelection from '@/components/controls/product-selection.vue';
import Checkbox from '@/components/controls/checkbox.vue';

export default Vue.extend({
  name: 'EditBeforeAfterImageView',
  inject: {
    $validator: '$validator',
  },
  components: {
    FormInput,
    NotificationBanner,
    Publish,
    Autocomplete,
    ImageUploadControl,
    PracticeSelectionControl,
    ProductSelection,
    Checkbox,
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      treatmentsSearchInput: '',
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),
    /**
     * Before After Images
     */
    ...mapState('before-after-images', ['editable', 'editableStatus']),
    ...mapGetters('before-after-images', {
      isLoading: 'editableIsLoading',
      isSaving: 'editableIsSaving',
      hasUnsavedChanges: 'editableHasUnsavedChanges',
    }),

    /**
     * Products
     */
    ...mapState('products', {
      productsTree: 'tree',
    }),

    /**
     * Component
     */
    isFetching() {
      return this.isLoading || this.isSaving;
    },
    isInactive() {
      return this.editable.status === BeforeAfterImagesStatus.Inactive;
    },
    treatments(): Taxonomy[] {
      if (this.editable && this.editable.product && this.editable.product.id && this.productsTree.length) {
        const product = this.productsTree.find((item) => item.id === this.editable.product.id);

        if (product) {
          return product.treatments;
        }
      }

      return [];
    },
    treatmentsOptions(): AutocompleteOption[] {
      const ids = this.editable.treatments.map(({ id }) => id);

      return this.treatments
        .filter(({ id }) => ids.indexOf(id) === -1)
        .map(({ id, title }) => ({
          label: title,
          value: id,
        }))
        .filter(({ label }) => label.toLowerCase().indexOf(this.treatmentsSearchInput.toLowerCase()) > -1);
    },
    licenceAgreementURL() {
      return `${process.env.BASE_URL}assets/BA_LicensingAgreement_Feb2021_FORM.pdf`;
    },
  },
  methods: {
    ...mapActions('before-after-images', {
      update: BeforeAfterImagesActions.UPDATE_EDITABLE,
      save: BeforeAfterImagesActions.SAVE_EDITABLE,
      removeAction: BeforeAfterImagesActions.DELETE,
      restoreAction: BeforeAfterImagesActions.RESTORE,
    }),
    selectProduct(productId?: number) {
      let product;

      if (productId) {
        product = this.productsTree.find((item) => item.id === productId);
      }

      if (!product) {
        this.update({ product: null, treatments: [] });
        return;
      }

      if (this.editable.product && product.id === this.editable.product.id) {
        return;
      }

      this.update({
        product: ProductRelationship.clone(product),
        treatments: [],
      });
    },
    addTreatment(option: AutocompleteOption) {
      const treatment = this.treatments.find((item) => item.id === option.value);
      if (!treatment) {
        return;
      }

      const treatments = [...this.editable.treatments];

      treatments.push(Taxonomy.clone(treatment));

      this.update({ treatments });
    },
    removeTreatment(treatmentId: number) {
      const treatments = this.editable.treatments.filter(({ id }) => id !== treatmentId);

      this.update({ treatments });
    },
    remove() {
      this.removeAction({ id: this.editable.id });
    },
    restore() {
      this.restoreAction({ id: this.editable.id });
    },
    updateImageData(data) {
      const image = BeforeAfterImage.clone(this.editable.images[0]);

      Object.keys(data).forEach((key) => {
        image[key] = data[key];
      });

      this.update({ images: [image] });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      const answer = window.confirm(this.$t('Do you really want to leave? You have unsaved changes!'));

      if (!answer) {
        next(false);
        return;
      }
    }

    next();
  },
});
