
import '@/icons';
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';

import { UserActions } from '@/store/modules/user/types';
import SubmitButton from '@/components/controls/submit-button.vue';
import MarkdownRenderer from '@/components/blocks/markdown-renderer.vue';

export default Vue.extend({
  name: 'SupportView',
  inject: {
    $validator: '$validator',
  },
  components: {
    SubmitButton,
    MarkdownRenderer,
  },
  data() {
    return {
      hasError: false,
      error: '',
      comment: '',
      expandedFaq: 0,
    };
  },
  computed: {
    ...mapGetters('user', ['isSupportPending', 'isSupportSuccess', 'isSupportFailed']),
    ...mapGetters('user/profile', ['isUser', 'isInNorthAmerica']),
    ...mapState('support', ['faqList']),
    contactDescription() {
      if (this.isInNorthAmerica) {
        return this.$t(
          'To contact us via telephone, please call us toll free at 1 (800) 886 - 2966 or use the contact form below to contact us via email.',
        );
      }

      return this.$t('Use the form below to contact us via email.');
    },
    showFAQs() {
      return this.faqList.length > 0;
    },
  },
  methods: {
    ...mapActions('user', [UserActions.REQUEST_SUPPORT]),
    fieldLength(e) {
      if (this.comment.length > 999 && e.keyCode !== 8 && e.keyCode !== 46) {
        this.hasError = true;
        this.error = this.$t('Please keep your support request to less than 1000 characters.');
        this.comment = this.comment.substring(0, 1000);
        e.preventDefault();
      } else {
        this.hasError = false;
      }
    },
    requestSupport() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.hasError = false;
          // This will be the error that is displayed in a network failure
          this.error = this.$t('Something has gone wrong. If this persists, email us at %(email)%.', {
            email: '<a href="mailto:ampssupport@cynosure.com">ampssupport@cynosure.com</a>',
            interpolation: { escapeValue: false },
          });
          const comment = this.comment;
          this.comment = '';
          return this[UserActions.REQUEST_SUPPORT]({ comment });
        } else {
          this.hasError = true;
          this.error = this.$t('Please provide a short description of the problems you are having.');
        }
      });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.hash) {
          let id: number = 0;

          if (to.hash.substr(0, 5) === '#faq-') {
            id = Number(to.hash.substr(5));
          }

          if (Number.isNaN(id) || id < 1) {
            return;
          }

          const faq = this.faqList.find((item) => item.id === id);

          if (faq) {
            this.expandedFaq = faq.id;
          }
        }
      },
    },
  },
});
