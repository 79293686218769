
import Vue from 'vue';
import marked from 'marked';

export default Vue.extend({
  name: 'MarkedRenderer',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    renderedHtml() {
      try {
        return marked(this.value, { sanitize: true, baseUrl: window.location.origin });
      } catch (error) {
        console.error(error);
        return 'error';
      }
    },
  },
});
