import { createCRUDEndpoints } from '@/api/factory/crud';
import { BeforeAfterJSON } from '@/store/modules/before-after-images/before-after';
type ListParams =
  | {
      practice_id?: number;
      product_id?: number;
      title?: string;
      search?: string;
      status?: '' | '0' | '1';
      page?: string | number;
      sort?: string;
      direction?: 'asc' | 'desc';
    }
  | undefined;
export const { list, get, store, remove, restore } = createCRUDEndpoints<BeforeAfterJSON, ListParams>({
  slug: 'before_after',
});
