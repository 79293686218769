
import Vue from 'vue';

import { mapGetters, mapActions } from 'vuex';

import {
  BeforeAfterImagesStatuses,
  BeforeAfterImagesStatus,
  BeforeAfterImagesActions,
} from '@/store/modules/before-after-images/types';

import SubmitButton from '@/components/controls/submit-button.vue';

import PracticeSelectionControl from '@/components/controls/practice-selection.vue';

import Dropdown, { DropdownItems } from '@/components/controls/dropdown.vue';

export default Vue.extend({
  name: 'BeforeAfterImagesFilterForm',

  components: {
    SubmitButton,

    PracticeSelectionControl,

    Dropdown,
  },

  props: {
    loading: {
      type: Boolean,

      default: false,
    },

    selectedItems: {
      type: Array,

      default: () => [] as number[],
    },
  },

  data() {
    return {
      availableStatuses: BeforeAfterImagesStatuses,

      search: '',

      status: '',

      practice: undefined,
    };
  },

  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),

    dropdownItems() {
      const items: DropdownItems = {};

      items.reset = {
        label: this.$t('Reset Filters'),

        icon: 'close',
      };

      if ((this.isSuperAdmin || this.isAdmin) && this.selectedItems && this.selectedItems.length) {
        items.restore = {
          hasTopBorder: true,

          label: this.$t('Restore'),

          count: this.selectedItems.length,

          disabled: !this.selectedItems.length,
        };

        items.remove = {
          label: this.$t('Delete'),

          count: this.selectedItems.length,

          disabled: !this.selectedItems.length,
        };
      }

      return items;
    },
  },

  methods: {
    ...mapActions('before-after-images', {
      deleteBAImages: BeforeAfterImagesActions.BULK_DELETE,

      restoreBAImages: BeforeAfterImagesActions.BULK_RESTORE,
    }),

    initFilters(): void {
      this.search = this.$router.currentRoute.query.search || '';

      this.status = this.$router.currentRoute.query.status || BeforeAfterImagesStatus.Active;

      let newPracticeValue;

      if (this.$router.currentRoute.query.practice_id) {
        newPracticeValue = Number(this.$router.currentRoute.query.practice_id);

        if (!newPracticeValue || Number.isNaN(newPracticeValue)) {
          newPracticeValue = undefined;
        }
      }

      if (this.practice !== newPracticeValue) {
        this.practice = newPracticeValue;
      }
    },

    applyFilters(): void {
      const { sort, direction, ...queryParams } = this.$route.query;

      this.$router.push({
        params: {
          page: 1,
        },

        query: {
          ...queryParams,

          search: this.search,

          status: this.status,

          practice_id: this.practice,
        },
      });
    },

    handleDropdownClick(action: string): void {
      if ('reset' === action) {
        this.$router.push({
          params: {
            page: 1,
          },

          query: {},
        });
      }

      if ('remove' === action) {
        this.deleteBAImages({ ids: this.selectedItems });
      }

      if ('restore' === action) {
        this.restoreBAImages({ ids: this.selectedItems });
      }
    },
  },

  created(): void {
    this.initFilters();
  },

  watch: {
    $route(): void {
      this.initFilters();
    },
  },
});
