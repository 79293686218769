import { ActionTree } from 'vuex';
import api from '@/api';
import i18next from '@/i18n';
import router from '@/router';
import { RootState, ErrorMessages, RootActions, EditableStatus, ListStatus } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { NotificationsActions } from '@/store/modules/notifications/types';
import { prepareStatusFilter } from '@/store/utils/filters';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import BeforeAfter from './before-after';
import { BeforeAfterImagesState, BeforeAfterImagesActions, BeforeAfterImagesMutations } from './types';
export const actions: ActionTree<BeforeAfterImagesState, RootState> = {
  async [BeforeAfterImagesActions.LOAD_LIST]({ commit, dispatch }, filters = {}): Promise<void> {
    commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Loading);
    try {
      const response = await api.before_afters.list(prepareStatusFilter(filters));
      const items = response.data.data.map((item) => BeforeAfter.fromJSON(item));
      commit(BeforeAfterImagesMutations.SET_LIST_PAGINATION, Pagination.fromJSON(response.data));
      commit(BeforeAfterImagesMutations.SET_LIST, items);
      commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      // error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
      commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [BeforeAfterImagesActions.LOAD_EDITABLE]({ commit, dispatch }, { id, practice_id } = {}): Promise<void> {
    commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);
    if (!id) {
      const beforeAfter = new BeforeAfter();
      const practiceId = Number(practice_id);
      if (practiceId && Number.isFinite(practiceId)) {
        return api.practices
          .get(practiceId)
          .then((res) => {
            beforeAfter.practice = PracticeRelationship.fromJSON(res.data as PracticeRelationshipJSON);
            commit(BeforeAfterImagesMutations.SET_EDITABLE, beforeAfter);
            commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
          })
          .catch((error) => {
            error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
            commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
            return dispatch(RootActions.ERROR, error, { root: true });
          });
      }
      commit(BeforeAfterImagesMutations.SET_EDITABLE, beforeAfter);
      commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      return Promise.resolve();
    }
    try {
      const response = await api.before_afters.get(id);
      commit(BeforeAfterImagesMutations.SET_EDITABLE, BeforeAfter.fromJSON(response.data));
      commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
    } catch (error) {
      //error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
      commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  [BeforeAfterImagesActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(BeforeAfterImagesMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },
  async [BeforeAfterImagesActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;
    try {
      await new Promise<void>((resolve, reject) => {
        if (state.editable) {
          return resolve();
        }
        reject(new Error('Invalid data!'));
      });
      commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
      isUpdate = Boolean(state.editable!.id);
      successMsg = i18next.t('A new before-after image was added successfully.');
      if (isUpdate) {
        successMsg = i18next.t('The before-after image was successfully updated.');
      }
      const response = await api.before_afters.store(state.editable!.toJSON());
      commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
      commit(BeforeAfterImagesMutations.SET_EDITABLE, BeforeAfter.fromJSON(response.data));
      return dispatch('notifications/' + NotificationsActions.SHOW_SUCCESS, { body: successMsg }, { root: true });
    } catch (error) {
      //error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
      commit(BeforeAfterImagesMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [BeforeAfterImagesActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Loading);
    try {
      await new Promise<void>((resolve, reject) => {
        if (!id) {
          return reject(new Error('Invalid data!'));
        }
        resolve();
      });
      await api.before_afters.remove(id);
      await dispatch(BeforeAfterImagesActions.LOAD_LIST, {
        ...router.currentRoute.params,
        ...router.currentRoute.query,
      });
      return dispatch(
        'notifications/' + NotificationsActions.SHOW_SUCCESS,
        { body: i18next.t('The before-after image was deleted successfully.') },
        { root: true },
      );
    } catch (error) {
      //error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
      commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [BeforeAfterImagesActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Loading);
    try {
      await new Promise<void>((resolve, reject) => {
        if (!id) {
          return reject(new Error('Invalid data!'));
        }
        resolve();
      });
      await api.before_afters.restore(id);
      await dispatch(BeforeAfterImagesActions.LOAD_LIST, {
        ...router.currentRoute.params,
        ...router.currentRoute.query,
      });
      return await dispatch(
        'notifications/' + NotificationsActions.SHOW_SUCCESS,
        { body: i18next.t('The before-after image was restored successfully.') },
        { root: true },
      );
    } catch (error) {
      //error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
      commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  [BeforeAfterImagesActions.BULK_RESTORE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Loading);
    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }
      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.before_afters.restore(id))))
      .then(() => {
        dispatch(BeforeAfterImagesActions.LOAD_LIST, { ...router.currentRoute.params, ...router.currentRoute.query });
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          { body: i18next.t('%(count)% before-after image was restored successfully', { count: ids.length }) },
          { root: true },
        );
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
        commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [BeforeAfterImagesActions.BULK_DELETE](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Loading);
    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }
      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.before_afters.remove(id))))
      .then(() => {
        dispatch(BeforeAfterImagesActions.LOAD_LIST, { ...router.currentRoute.params, ...router.currentRoute.query });
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          { body: i18next.t('%(count)% before-after image was deleted successfully', { count: ids.length }) },
          { root: true },
        );
      })
      .catch((error) => {
        error.errorMessage = ErrorMessages.DATA_LOAD_ERROR;
        commit(BeforeAfterImagesMutations.SET_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};
export default actions;
