var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"c-main"},[_c('div',{staticClass:"o-container-fluid"},[_c('div',{staticClass:"c-page-headline c-page-headline__group"},[_c('h1',{staticClass:"c-page-title c-page-title--smaller"},[_vm._v(" "+_vm._s(_vm.$t('Before-After Images'))+" ")]),_c('p',{staticClass:"c-page-action"},[(_vm.isLoaded)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.formatNumber(_vm.pagination.total)))]),_vm._v(" "+_vm._s(_vm.$t('before-after images found')))]):_vm._e(),_c('router-link',{attrs:{"to":{
              name: 'add-before-after-images',
              query: { practice_id: _vm.$route.query.practice_id },
            }}},[_c('span',{staticClass:"c-plus-mini"},[_vm._v("+")]),_vm._v(" "+_vm._s(_vm.$t('Add New')))])],1)]),_c('hr',{staticClass:"c-divider"}),_c('notification-banner'),_c('before-after-images-filter-form',{attrs:{"selected-items":_vm.selectedItems}}),(_vm.isLoading)?_c('table-list-skeleton'):(_vm.isLoaded)?_c('div',{staticClass:"c-table__wrapper u-move-up"},[_c('table',{staticClass:"c-table",class:{ 'c-table--disabled': _vm.isEmpty }},[_c('thead',[_c('tr',[(_vm.isSuperAdmin || _vm.isAdmin)?_c('th',{staticClass:"c-table__bulk"},[_c('checkbox-control',{attrs:{"value":_vm.areAllItemsSelected},on:{"input":_vm.selectAll}})],1):_vm._e(),_c('th',{class:_vm.columnClasses('title'),on:{"click":function($event){return _vm.applySort('title')}}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('Before-After Image Name'))+" "),_c('svgicon',{attrs:{"name":"chevron"}})],1)]),(_vm.isSuperAdmin || _vm.isAdmin)?_c('th',{class:_vm.columnClasses('practice_id'),on:{"click":function($event){return _vm.applySort('practice_id')}}},[_c('div',[_vm._v(_vm._s(_vm.$t('Practice'))+" "),_c('svgicon',{attrs:{"name":"chevron"}})],1)]):_vm._e(),_c('th',{class:_vm.columnClasses('product_id'),on:{"click":function($event){return _vm.applySort('product_id')}}},[_c('div',[_vm._v(_vm._s(_vm.$t('Product'))+" "),_c('svgicon',{attrs:{"name":"chevron"}})],1)]),(_vm.isSuperAdmin || _vm.isAdmin)?_c('th',{class:_vm.columnClasses('user_id'),on:{"click":function($event){return _vm.applySort('user_id')}}},[_c('div',[_vm._v(_vm._s(_vm.$t('User'))+" "),_c('svgicon',{attrs:{"name":"chevron"}})],1)]):_vm._e(),_c('th',[_vm._v(" ")])])]),(_vm.isEmpty)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.isSuperAdmin || _vm.isAdmin ? 6 : 3}},[_c('p',{staticClass:"c-table__message u-text-center"},[_vm._v(" "+_vm._s(_vm.$t('No Before-After Images Found'))+" ")])])])]):_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id,class:{
                'is-inactive': !item.status,
                'is-selected': _vm.isSelected(item),
              }},[(_vm.isSuperAdmin || _vm.isAdmin)?_c('td',{staticClass:"c-table__bulk"},[_c('checkbox-control',{attrs:{"value":_vm.isSelected(item)},on:{"input":function($event){return _vm.selectItem(item)}}})],1):_vm._e(),(item.status)?_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'edit-before-after-images',
                    params: { id: item.id },
                  }}},[_vm._v(_vm._s(item.title))])],1):_c('td',[_vm._v(_vm._s(item.title))]),(_vm.isSuperAdmin || _vm.isAdmin)?_c('td',[(item.practice && item.practice.id)?_c('router-link',{attrs:{"to":{
                    name: 'view-practice',
                    params: { id: item.practice.id },
                  }}},[_vm._v(_vm._s(item.practice.title))]):_vm._e()],1):_vm._e(),(_vm.isSuperAdmin || _vm.isAdmin)?_c('td',[(item.product && item.product.id)?_c('router-link',{attrs:{"to":{
                    name: 'edit-product',
                    params: { id: item.product.id },
                  }}},[_vm._v(_vm._s(item.product.title))]):_vm._e()],1):(item.product && item.product.id)?_c('td',[_vm._v(" "+_vm._s(item.product.title)+" ")]):_c('td',[_vm._v(" ")]),(_vm.isSuperAdmin || _vm.isAdmin)?_c('td',[(item.user && item.user.id)?_c('router-link',{attrs:{"to":{ name: 'edit-user', params: { id: item.user.id } }}},[_vm._v(_vm._s(item.user.title))]):_vm._e()],1):_vm._e(),(item.status)?_c('td',{attrs:{"width":"40px"}},[_c('dropdown',{attrs:{"items":{
                    edit: {
                      label: _vm.$t('Change Before-After Image'),
                      icon: 'pencil',
                    },
                    remove: {
                      label: _vm.$t('Delete'),
                      hasTopBorder: true,
                      icon: 'trash',
                    },
                  }},on:{"click":function($event){return _vm.handleClick(item, $event)}}})],1):_c('td',[_c('button',{on:{"click":function($event){$event.preventDefault();return _vm.handleClick(item, 'restore')}}})])])}),0)])]):_vm._e(),_c('pagination-control',{attrs:{"current-page":_vm.pagination.currentPage,"last-page":_vm.pagination.lastPage}})],1)]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }