import Vue from 'vue';
import { MutationTree } from 'vuex';

import { EditableStatus, ListStatus } from '@/store/types';
import { updateEditable } from '@/store/utils/editable';
import Pagination from '@/store/models/pagination';

import { BeforeAfterImagesState, BeforeAfterImagesMutations } from './types';
import BeforeAfter from './before-after';

export const mutations: MutationTree<BeforeAfterImagesState> = {
  /**
   * List
   */
  [BeforeAfterImagesMutations.SET_LIST](state, items: BeforeAfter[]) {
    state.list = items;
  },
  [BeforeAfterImagesMutations.SET_LIST_PAGINATION](state, pagination: Pagination) {
    state.listPagination = pagination;
  },
  [BeforeAfterImagesMutations.SET_LIST_STATUS](state, status: ListStatus) {
    state.status = status;
  },

  /**
   * Edit
   */
  [BeforeAfterImagesMutations.SET_EDITABLE](state, item: BeforeAfter) {
    Vue.set(state, 'editable', item);
    Vue.set(state, 'initialEditable', BeforeAfter.clone(item));
  },
  [BeforeAfterImagesMutations.SET_EDITABLE_STATUS](state, editableStatus: EditableStatus) {
    state.editableStatus = editableStatus;
  },
  [BeforeAfterImagesMutations.UPDATE_EDITABLE]: updateEditable<BeforeAfterImagesState, BeforeAfter>('editable'),
};

export default mutations;
