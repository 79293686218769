import { JSONModel } from '@/store/models/jsonModel';
import Image, { ImageJSON } from '@/store/models/image';

export interface BeforeAfterImageJSON {
  id: number;
  before: ImageJSON;
  after: ImageJSON;
  time_between: string;
}

export default class BeforeAfterImage extends JSONModel<BeforeAfterImageJSON> {
  public id: number = 0;
  public before?: Image;
  public after?: Image;
  public timeBetween: string = '';

  public fromJSON(json: BeforeAfterImageJSON) {
    if (json.id) {
      this.id = Number(json.id);
    }

    if (json.before) {
      this.before = Image.fromJSON(json.before);
    }

    if (json.after) {
      this.after = Image.fromJSON(json.after);
    }

    if (json.time_between) {
      this.timeBetween = String(json.time_between);
    }
  }
}
