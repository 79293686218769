
import Vue from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import Pagination from '@/store/models/pagination';
import BeforeAfter from '@/store/modules/before-after-images/before-after';
import { BeforeAfterImagesActions } from '@/store/modules/before-after-images/types';
import { formatNumber } from '@/i18n';

import tableViewMixin from '@/components/mixins/table-view';
import NotificationBanner from '@/components/blocks/notification-banner.vue';
import TableListSkeleton from '@/components/skeletons/table-list.vue';
import Dropdown from '@/components/controls/dropdown.vue';
import PaginationControl from '@/components/controls/pagination.vue';
import BeforeAfterImagesFilterForm from '@/components/forms/before-after-images-filter.vue';
import CheckboxControl from '@/components/controls/checkbox.vue';

export default mixins(tableViewMixin).extend({
  name: 'BeforeAfterImagesView',
  components: {
    NotificationBanner,
    TableListSkeleton,
    Dropdown,
    PaginationControl,
    BeforeAfterImagesFilterForm,
    CheckboxControl,
  },
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    ...mapGetters('user/profile', ['isSuperAdmin', 'isAdmin']),

    /**
     * Before-After Images
     */
    ...mapState('before-after-images', {
      items: 'list',
      pagination: 'listPagination',
    }),
    ...mapGetters('before-after-images', ['isLoading', 'isLoaded', 'isEmpty']),

    areAllItemsSelected(): boolean {
      return Boolean(this.items.length) && this.selectedItems.length === this.items.length;
    },
  },
  methods: {
    ...mapActions('before-after-images', {
      deleteAction: BeforeAfterImagesActions.DELETE,
      restoreAction: BeforeAfterImagesActions.RESTORE,
    }),
    formatNumber,
    handleClick(item: BeforeAfter, action: string) {
      if ('edit' === action) {
        this.$router.push({
          name: 'edit-before-after-images',
          params: { id: String(item.id) },
        });
      } else if ('remove' === action) {
        const answer = window.confirm(this.$t('Do you really want to delete this before-after image?'));

        if (answer) {
          this.deleteAction({ id: item.id });
        }
      } else if ('restore' === action) {
        const answer = window.confirm(this.$t('Do you really want to restore this before-after image?'));

        if (answer) {
          this.restoreAction({ id: item.id });
        }
      }
    },
    isSelected(beforeAfter: BeforeAfter): boolean {
      return this.selectedItems.indexOf(beforeAfter.id) !== -1;
    },
    selectItem(beforeAfter: BeforeAfter) {
      if (this.isSelected(beforeAfter)) {
        this.selectedItems = this.selectedItems.filter((item) => item !== beforeAfter.id);
        return;
      }

      this.selectedItems.push(beforeAfter.id);
    },
    selectAll() {
      if (this.areAllItemsSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = this.items.map((beforeAfter: BeforeAfter) => beforeAfter.id);
      }
    },
  },
});
