
import { mapGetters, mapActions, mapState } from 'vuex';
import mixins from 'vue-typed-mixins';

import config from '@/config';
import { UserActions } from '@/store/modules/user/types';
import LocationsMenuBlock from '@/components/blocks/locations-menu.vue';
import PractitionersMenuBlock from '@/components/blocks/practitioners-menu.vue';
import imageSizeMixin from '@/components/mixins/image-size';
import domUtils from '@/components/mixins/dom-utils';

export default mixins(imageSizeMixin, domUtils).extend({
  name: 'MainHeaderLayout',
  components: {
    LocationsMenuBlock,
    PractitionersMenuBlock,
  },
  data() {
    return {
      currentRouteName: '',
      isGuideAttached: false,
    };
  },
  computed: {
    ...mapState('user/profile', {
      currentUser: 'currentProfile',
    }),
    ...mapGetters('user/profile', [
      'displayName',
      'profileImage',
      'isSuperAdmin',
      'isAdmin',
      'isClinical',
      'isSales',
      'isUser',
      'showTermsModal',
      'showTermsBanner',
      'isInNorthAmerica',
      'isInUK',
    ]),
    sizedProfileImage() {
      return this.sizedUserProfileImage(this.profileImage);
    },
    createResourceUrl() {
      return config.DRUPAL_URL + 'node/add';
    },
  },
  methods: {
    ...mapActions('user', {
      logout: UserActions.LOGOUT,
    }),
    toggleMobileHandler(event) {
      this.toggleBodyClass('site-navigation--open');
    },

    isRoute(routes: string | string[]) {
      const names = (Array.isArray(routes) ? routes : [routes])
        // Cast to string
        .map((val) => String(val))
        // Filter empty strings and invalid values
        .filter((val) => !!val);

      if (!names.length || !this.currentRouteName) {
        return false;
      }

      return names.indexOf(this.currentRouteName) > -1;
    },
    attachGuide() {
      if (
        this.isGuideAttached ||
        !this.isUser ||
        this.showTermsModal ||
        this.showTermsBanner ||
        'yes' === localStorage.getItem('guide-completed')
      ) {
        return;
      }

      const completeGuide = () => localStorage.setItem('guide-completed', 'yes');
      this.$intro()
        .addStep({
          intro:
            '<h3>' +
            this.$t('Welcome to AMPS Advantage!') +
            '</h3><p>' +
            this.$t('We worked hard on making your AMPS experience better since the last time you were here.') +
            '</p><p>' +
            this.$t('Take this short tour to see some of the things we improved.') +
            '</p>',
        })
        .addStep({
          element: document.getElementById('intro-my-practice'),
          intro:
            '<h3>' +
            this.$t('Your Practice') +
            '</h3><p>' +
            this.$t('Hover over the menu and quickly access or add new locations and practitioners.') +
            '</p><p>' +
            this.$t(
              'With the new AMPS Advantage edit screens, it is much easier to connect practitioners to locations and vice versa.',
            ) +
            '</p>',
          position: 'bottom',
        })
        .addStep({
          element: document.getElementById('intro-resource-center'),
          intro:
            '<h3>' +
            this.$t('The New Resource Center') +
            '</h3><p>' +
            this.$t(
              'Locate turnkey marketing materials, digital assets, and customizable resources in the Resource Center!',
            ) +
            '</p><p>' +
            this.$t('Use the search box and/or filter on the left side of the page to drill down further.') +
            '</p>',
          position: 'bottom',
        })
        .addStep({
          element: document.getElementById('intro-clinical-support'),
          intro:
            '<h3>' +
            this.$t('Cynosure Training') +
            '</h3><p>' +
            this.$t(
              'Inside the Clinical Training tab, you can find all clinical and educational materials on your device.',
            ) +
            '</p><p>' +
            this.$t('Use the search box and/or filters on the left side of the page to narrow your search further.') +
            '</p>',
          position: 'bottom',
        })
        .addStep({
          element: document.getElementById('intro-my-profile'),
          intro:
            '<h3>' +
            this.$t('Your Profile') +
            '</h3><p>' +
            this.$t(
              'Your profile is just the same as before! You can still use your existing email and password to access AMPS Advantage.',
            ) +
            '</p><p>' +
            this.$t('Feel free to contact us if you have any questions!') +
            '</p>',
          position: 'left',
        })
        .oncomplete(completeGuide)
        .onexit(completeGuide)
        // Start the guide
        .start();
    },
  },
  watch: {
    $route: {
      // the handler will be called immediately after the start of the observation
      immediate: true,
      handler(to, from) {
        this.removeBodyClass('site-navigation--open');
        this.currentRouteName = to.name || '';
      },
    },
  },
  mounted() {
    this.$watch(
      (component) => {
        return {
          modal: component.showTermsModal,
          banner: component.showTermsBanner,
        };
      },
      (val) => {
        if (!val.modal && !val.banner) {
          this.attachGuide();
        }
      },
      { immediate: true },
    );
  },
});
