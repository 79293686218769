import { ActionTree } from 'vuex';

import router from '@/router';
import i18next, { setLocale } from '@/i18n';
import api from '@/api';
import { EnhancedAxiosError } from '@/api/utils';

import { prepareFilters } from '@/store/utils/filters';
import Pagination from '@/store/models/pagination';
import User, { UserStatus } from '@/store/models/user';
import { RootState, ListStatus, RootActions, ErrorMessages, EditableStatus } from '@/store/types';
import { NotificationsActions } from '@/store/modules/notifications/types';

import { UsersState, UsersActions, UsersMutations } from './types';
import PracticeRelationship, { PracticeRelationshipJSON } from '../practices/practice-relationship';
import { SendWelcomeEmailParams } from '@/api/endpoints/users';
import { enhanceErrorWithMessage } from '@/utils/errors';
import blogApi from '@/store/utils/blog-api';

export const actions: ActionTree<UsersState, RootState> = {
  async [UsersActions.LOAD_LIST]({ commit, dispatch }, filters = {}) {
    commit(UsersMutations.SET_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.users.list(prepareFilters(filters, 'users'));
      commit(UsersMutations.SET_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        UsersMutations.SET_LIST,
        res.data.data.map((item) => User.fromJSON(item)),
      );
      commit(UsersMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(UsersMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  [UsersActions.LOAD_EDITABLE]({ commit, dispatch }, { id, practice_id } = {}): Promise<void> {
    commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);
    commit(UsersMutations.SET_SENDING_WELCOME, null);

    if (!id) {
      const user = new User();
      const practiceId = Number(practice_id);

      if (practiceId && Number.isFinite(practiceId)) {
        return api.practices
          .get(practiceId)
          .then((res) => {
            user.practice = PracticeRelationship.fromJSON(res.data as PracticeRelationshipJSON);

            commit(UsersMutations.SET_EDITABLE, user);
            commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
          })
          .catch((error) => {
            enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

            commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
            return dispatch(RootActions.ERROR, error, { root: true });
          });
      }

      commit(UsersMutations.SET_EDITABLE, user);
      commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      return Promise.resolve();
    }

    return api.users
      .get(id)
      .then((response) => response.data)
      .then((response) => {
        commit(UsersMutations.SET_EDITABLE, User.fromJSON(response));
        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [UsersActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(UsersMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [UsersActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.editable!.id);
        successMsg = i18next.t('A new user was added successfully.');

        if (isUpdate) {
          successMsg = i18next.t('The user was successfully updated.');
        }
      })
      .then(() => api.users.store(state.editable!.toJSON()))
      .then((response) => response.data)
      .then((response): Promise<User> => {
        const user = User.fromJSON(response);

        commit(UsersMutations.SET_EDITABLE, user);
        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!user.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-user',
                params: {
                  id: String(user.id),
                },
                query: {
                  open_welcome_email_modal: '1',
                },
              },
              () => resolve(user),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(user);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (error && error.response && error.response.data && error.response.data.errors) {
          const errorValues = Object.keys(error.response.data.errors)
            // Grab object values
            .map((key) => error.response.data.errors[key]);

          const errorStrings: string[] = []
            .concat(...errorValues)
            // Filter out empty strings
            .filter(Boolean);

          if (errorStrings.length) {
            enhanceErrorWithMessage(error, errorStrings.join('\n'));
          }
        }

        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [UsersActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.users.remove(id))
      .then(() => {
        commit(UsersMutations.UPDATE_INITIAL_EDITABLE, {
          status: UserStatus.Inactive,
        });
        commit(UsersMutations.UPDATE_EDITABLE, { status: UserStatus.Inactive });
        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The user was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [UsersActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-user';
    if (isEdit) {
      commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(UsersMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.users.restore(id))
      .then(() => {
        if (isEdit) {
          commit(UsersMutations.UPDATE_INITIAL_EDITABLE, {
            status: UserStatus.Active,
          });
          commit(UsersMutations.UPDATE_EDITABLE, { status: UserStatus.Active });
          commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(UsersActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The user was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(UsersMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [UsersActions.SEND_WELCOME_MAIL]({ commit, dispatch }, welcomeEmailParams: SendWelcomeEmailParams): Promise<void> {
    commit(UsersMutations.SET_SENDING_WELCOME, true);

    return api.users
      .sendWelcomeEmail(welcomeEmailParams)
      .then(async () => {
        await dispatch(UsersActions.UPDATE_EDITABLE, { status: UserStatus.WaitingActivation });
        commit(UsersMutations.SET_SENDING_WELCOME, false);
        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('Activation email was successfully sent.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(UsersMutations.SET_SENDING_WELCOME, false);
        commit(UsersMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [UsersActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(UsersMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.users.restore(id))))
      .then(() => {
        dispatch(UsersActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% user was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(UsersMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [UsersActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(UsersMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve(undefined);
    })
      .then(() => Promise.all(ids.map((id) => api.users.remove(id))))
      .then(() => {
        dispatch(UsersActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% user was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(UsersMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
