import { JSONModel, toJSONContext } from '@/store/models/jsonModel';
import BeforeAfterImage, { BeforeAfterImageJSON } from './before-after-image';
import PracticeRelationship, { PracticeRelationshipJSON } from '@/store/modules/practices/practice-relationship';
import UserRelationship, { UserRelationshipJSON } from '@/store/modules/users/user-relationship';
import Taxonomy, { TaxonomyJSON } from '@/store/models/taxonomy';
import ProductRelationship, { ProductRelationshipJSON } from '@/store/modules/products/product-relationship';
import { BeforeAfterImagesStatus } from './types';
import config from '@/config';
export interface BeforeAfterJSON {
  id: number;
  status: BeforeAfterImagesStatus;
  practice_id: number;
  language_id: number;
  country_id: number;
  product_id: number;
  user_id: number;
  title: string;
  notes: string;
  created_at: string;
  updated_at: string;
  images: BeforeAfterImageJSON[];
  practice?: PracticeRelationshipJSON;
  user?: UserRelationshipJSON;
  treatments: TaxonomyJSON[];
  product?: ProductRelationshipJSON;
  zip?: string;
  consent: boolean;
  name: string;
  credentials: string;
  practice_name: string;
  phone_number: string;
  street: string;
  additional: string;
  city: string;
  state: string;
  postal_code: string;
}
export default class BeforeAfter extends JSONModel<BeforeAfterJSON> {
  public id: number = 0;
  public status: BeforeAfterImagesStatus = BeforeAfterImagesStatus.Active;
  public title: string = '';
  public notes: string = '';
  public createdAt: string = '';
  public updatedAt: string = '';
  public images: BeforeAfterImage[] = [new BeforeAfterImage()];
  public practice?: PracticeRelationship;
  public user?: UserRelationship;
  public treatments: Taxonomy[] = [];
  public product?: ProductRelationship;
  public zip: string = '';
  public consent: boolean = false;
  public name: string = '';
  public credentials: string = '';
  public practiceName: string = '';
  public phoneNumber: string = '';
  public street: string = '';
  public additional: string = '';
  public city: string = '';
  public state: string = '';
  public postalCode: string = '';
  get zipUrl(): string {
    if (this.zip !== '') {
      return config.API_HOSTNAME + this.zip.replace('/api', '');
    }
    return '';
  }
  public fromJSON(json: BeforeAfterJSON) {
    if (json.id) {
      this.id = Number(json.id);
    }
    if (json.status) {
      this.status =
        Number(json.status) === BeforeAfterImagesStatus.Active
          ? BeforeAfterImagesStatus.Active
          : BeforeAfterImagesStatus.Inactive;
    }
    if (json.title) {
      this.title = String(json.title);
    }
    if (json.notes) {
      this.notes = String(json.notes);
    }
    if (json.created_at) {
      this.createdAt = String(json.created_at);
    }
    if (json.updated_at) {
      this.updatedAt = String(json.updated_at);
    }
    if (json.images && Array.isArray(json.images)) {
      this.images = json.images.map((item) => BeforeAfterImage.fromJSON(item));
    }
    if (json.practice) {
      this.practice = PracticeRelationship.fromJSON(json.practice);
    }
    if (json.user) {
      this.user = UserRelationship.fromJSON(json.user);
    }
    if (json.treatments && Array.isArray(json.treatments)) {
      this.treatments = json.treatments.map((item) => Taxonomy.fromJSON(item));
    }
    if (json.product) {
      this.product = ProductRelationship.fromJSON(json.product);
    }
    if (json.zip) {
      this.zip = String(json.zip);
    }
    if (json.consent && Boolean(json.consent)) {
      this.consent = true;
    }
    if (json.name) {
      this.name = String(json.name);
    }
    if (json.credentials) {
      this.credentials = String(json.credentials);
    }
    if (json.practice_name) {
      this.practiceName = String(json.practice_name);
    }
    if (json.phone_number) {
      this.phoneNumber = String(json.phone_number);
    }
    if (json.street) {
      this.street = String(json.street);
    }
    if (json.additional) {
      this.additional = String(json.additional);
    }
    if (json.city) {
      this.city = String(json.city);
    }
    if (json.state) {
      this.state = String(json.state);
    }
    if (json.postal_code) {
      this.postalCode = String(json.postal_code);
    }
  }
  public toJSON(context: toJSONContext = 'server') {
    const json = super.toJSON(context);
    if (context !== 'server') {
      return json;
    }
    const { practice, product, ...other } = json;
    const out: BeforeAfterJSON = { ...other };
    if (practice) {
      out.practice_id = practice.id;
    }
    if (product) {
      out.product_id = product.id;
      out.product = product;
    }
    return out;
  }
}
