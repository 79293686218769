import api from '@/api';
import { EnhancedAxiosError } from '@/api/utils';
import router from '@/router';
import i18next from '@/i18n';

import { ListStatus, ActionsTree, RootActions, ErrorMessages } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { NotificationsActions, NotificationType } from '@/store/modules/notifications/types';
import { prepareFilters } from '@/store/utils/filters';
import { Snackbar } from '@/store/modules/notifications/snackbar';
import Report, { ReportType, ReportStatus } from './report';
import { ReportsState, ReportsActions, ReportsMutations } from './types';
import { enhanceErrorWithMessage } from '@/utils/errors';

const getRequestedReports = (userId: number): number[] => {
  const requestedReportsLocal = localStorage.getItem(`requestedReports_${userId}`);
  let requestedReports: number[] = [];

  if (requestedReportsLocal) {
    try {
      const parsed = Array.from(JSON.parse(requestedReportsLocal))
        .map((val: any) => Number(val))
        .filter(Boolean);

      if (parsed) {
        requestedReports = parsed;
      }
    } catch (e) {
      localStorage.removeItem(`requestedReports_${userId}`);
    }
  }

  return requestedReports;
};

const setRequestedReports = (userId: number, list: number[]): void => {
  let requestedReports = '[]';

  try {
    requestedReports = JSON.stringify(list);
  } catch (e) {
    requestedReports = '[]';
  }

  localStorage.setItem(`requestedReports_${userId}`, requestedReports);
};

const removeFromRequestedReports = (userId: number, reportId: number) => {
  setRequestedReports(
    userId,
    getRequestedReports(userId).filter((id) => id !== reportId),
  );
};

export const actions: ActionsTree<ReportsState, typeof ReportsActions> = {
  async [ReportsActions.LOAD_LIST]({ commit, dispatch }, filters = {}) {
    commit(ReportsMutations.SET_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.reports.list(filters);
      commit(ReportsMutations.SET_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        ReportsMutations.SET_LIST,
        res.data.data.map((item) => Report.fromJSON(item)),
      );
      commit(ReportsMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ReportsMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [ReportsActions.REQUEST]({ dispatch, rootGetters }) {
    if (!rootGetters['user/isLoggedIn'] || !rootGetters['user/profile/isSuperAdmin']) {
      return Promise.resolve();
    }

    let reportType: ReportType | undefined;
    const filters = prepareFilters({ ...router.currentRoute.query, type: router.currentRoute.name });
    const types = {
      'locations': ReportType.Location,
      'practitioners': ReportType.Practitioner,
      'resources': ReportType.Resource,
      'users': ReportType.User,
      'practices': ReportType.Practice,
      'products': ReportType.Product,
      'consultations': ReportType.Consultation,
      'resource-downloads': ReportType.Download,
      'clinical-support': ReportType.Resource,
      'clinical-support-downloads': ReportType.Download,
    };

    if (router.currentRoute.name && Object.keys(types).indexOf(router.currentRoute.name) !== -1) {
      reportType = types[router.currentRoute.name];
    }
    try {
      if (!reportType) {
        throw new Error('Unknown report type.');
      }

      const userId = rootGetters['user/profile/userId'];

      if (!userId) {
        throw new Error('Could not get user ID.');
      }

      const res = await api.reports.request(reportType, filters);

      if (!res.data.success || !res.data.report || !res.data.report.id) {
        throw new Error('Server problem!');
      }

      const requestedReports = getRequestedReports(userId);
      requestedReports.push(res.data.report.id);
      setRequestedReports(userId, requestedReports);

      return dispatch(
        'notifications/' + NotificationsActions.SHOW_SUCCESS,
        {
          body: i18next.t('Report request sent, it will be added to the report list in a minute or two once finished!'),
          bodyIcon: 'email-send',
          dismissible: true,
        },
        { root: true },
      );
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  async [ReportsActions.CHECK_REQUESTED]({ dispatch, rootGetters }) {
    if (!rootGetters['user/isLoggedIn'] || !rootGetters['user/profile/isSuperAdmin']) {
      return Promise.resolve();
    }

    try {
      const userId = rootGetters['user/profile/userId'];

      if (!userId) {
        throw new Error('Could not get user ID.');
      }

      const requestedReports = getRequestedReports(userId);

      if (!requestedReports || !Array.isArray(requestedReports) || !requestedReports.length) {
        return;
      }

      const res = await api.reports.get(requestedReports);

      if (!Array.isArray(res.data)) {
        return;
      }

      const reports = res.data.map((val) => Report.fromJSON(val));

      for (const report of reports) {
        if (report.status === ReportStatus.Processing) {
          continue;
        }

        removeFromRequestedReports(userId, report.id);

        const typeNames = {
          [ReportType.Practice]: i18next.t('Practices'),
          [ReportType.User]: i18next.t('Users'),
          [ReportType.Location]: i18next.t('Locations'),
          [ReportType.Practitioner]: i18next.t('Practitioners'),
          [ReportType.Resource]: i18next.t('Resources'),
          [ReportType.Product]: i18next.t('Products'),
          [ReportType.Consultation]: i18next.t('Consultation Requests'),
          [ReportType.Download]: i18next.t('Downloads'),
        };

        const reportType = typeNames[report.type];

        let title = i18next.t('%(reportType)% Report Error', { reportType });
        let message = i18next.t('there was a problem with creating your report, please try again.');
        let type = NotificationType.Error;

        if (report.status === ReportStatus.Processed) {
          title = i18next.t('%(reportType)% Report', { reportType });
          message = i18next.t('report is ready and available for %(downloadLink)%.', {
            downloadLink: `<a href="${report.url}" target="_blank">${i18next.t('download')}</a>`,
            interpolation: { escapeValue: false },
          });
          type = NotificationType.Success;
        }

        dispatch(
          'notifications/' + NotificationsActions.ADD_SNACKBAR,
          new Snackbar({
            icon: 'email-send',
            title,
            message,
            type,
            time: report.createdAt,
            timePrefix: i18next.t('requested') + ' ',
          }),
          { root: true },
        );
      }
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
  [ReportsActions.START_CHECKING_REQUESTED]({ state, dispatch, commit, rootGetters }) {
    const wait = () =>
      new Promise<void>((resolve) => {
        setTimeout(() => resolve(), 10000);
      });

    if (state.isReportsCheckRunning) {
      return Promise.resolve();
    }

    commit(ReportsMutations.START_CHECKING_REQUESTED);

    const fn = () => {
      if (!rootGetters['user/isLoggedIn'] || !rootGetters['user/profile/isSuperAdmin']) {
        return Promise.resolve();
      }

      return Promise.resolve()
        .then(() => wait())
        .then(() => dispatch(ReportsActions.CHECK_REQUESTED))
        .then(() => fn());
    };

    fn();
  },
};

export default actions;
