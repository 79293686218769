import * as accessories from './endpoints/accessories';
import * as assets from './endpoints/assets';
import * as auth from './endpoints/auth';
import * as before_afters from './endpoints/before_afters';
import * as categories from './endpoints/categories';
import * as consultations from './endpoints/consultations';
import * as countries from './endpoints/countries';
import * as enhancements from './endpoints/enhancements';
import * as events from './endpoints/events';
import * as languages from './endpoints/languages';
import * as listings from './endpoints/listings';
import * as personalize from './endpoints/personalize';
import * as practices from './endpoints/practices';
import * as practitioners from './endpoints/practitioners';
import * as products from './endpoints/products';
import * as public_sites from './endpoints/public_sites';
import * as public_treatments from './endpoints/public_treatments';
import * as reports from './endpoints/reports';
import * as settings from './endpoints/settings';
import * as statistics from './endpoints/statistics';
import * as support from './endpoints/support';
import * as treatments from './endpoints/treatments';
import * as upload from './endpoints/upload';
import * as users from './endpoints/users';
import * as privacy_requests from './endpoints/privacy_requests';
import { getCancelTokenSource, isCancel, isAxiosError, isAPIError, isAPILoginError } from './utils';

const api = {
  // Endpoints
  accessories,
  assets,
  auth,
  before_afters,
  categories,
  consultations,
  countries,
  enhancements,
  events,
  languages,
  listings,
  personalize,
  practices,
  practitioners,
  products,
  public_sites,
  public_treatments,
  reports,
  settings,
  statistics,
  support,
  treatments,
  upload,
  users,
  privacy_requests,

  // Utils
  getCancelTokenSource,
  isCancel,
  isAxiosError,
  isAPIError,
  isAPILoginError,
};

export default api;
