import api from '@/api';
import router from '@/router';
import i18next from '@/i18n';
import { EnhancedAxiosError } from '@/api/utils';

import { ListStatus, ActionsTree, EditableStatus, RootActions, ErrorMessages } from '@/store/types';
import Pagination from '@/store/models/pagination';
import { prepareFilters } from '@/store/utils/filters';
import { NotificationsActions } from '@/store/modules/notifications/types';

import { ProductsState, ProductsActions, ProductsMutations } from './types';
import Product, { ProductStatus } from './product';
import Accessory, { AccessoryStatus } from './accessory';
import AccessoryTree from './accessory-tree';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionsTree<ProductsState, typeof ProductsActions> = {
  async [ProductsActions.LOAD_LIST]({ commit, dispatch }, filters = {}) {
    commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.products.list(prepareFilters(filters, 'products'));
      commit(ProductsMutations.SET_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        ProductsMutations.SET_LIST,
        res.data.data.map((item: object) => Product.fromJSON(item)),
      );
      commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [ProductsActions.LOAD_TREE]({ commit, dispatch }) {
    commit(ProductsMutations.SET_TREE_STATUS, ListStatus.Loading);

    try {
      const res = await api.products.listTree();
      commit(
        ProductsMutations.SET_TREE,
        res.data.map((item) => Product.fromJSON(item)),
      );
      commit(ProductsMutations.SET_TREE_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ProductsMutations.SET_TREE_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [ProductsActions.LOAD_EDITABLE]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    if (!id) {
      const product = new Product();
      product.status = ProductStatus.Active;
      commit(ProductsMutations.SET_EDITABLE, product);
      commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      return Promise.resolve();
    }

    try {
      const response = await api.products.get(id);
      commit(ProductsMutations.SET_EDITABLE, Product.fromJSON(response.data));
      commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  [ProductsActions.UPDATE_EDITABLE]({ commit }, changes): Promise<void> {
    commit(ProductsMutations.UPDATE_EDITABLE, changes);
    return Promise.resolve();
  },

  [ProductsActions.SAVE_EDITABLE]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let url: string;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.editable!.id);
        url = 'product';
        successMsg = i18next.t('A new product was added successfully.');

        if (isUpdate) {
          url += `/${state.editable!.id}`;
          successMsg = i18next.t('The product was successfully updated.');
        }
      })
      .then(() => api.products.store(state.editable!.toJSON()))
      .then((response) => response.data)
      .then((response): Promise<Product> => {
        const product = Product.fromJSON(response);

        commit(ProductsMutations.SET_EDITABLE, product);
        commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!product.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-product',
                params: {
                  id: String(product.id),
                },
              },
              () => resolve(product),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(product);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.SAVE_TRANSLATIONS]({ state, commit, dispatch }, translations): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.editable) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(ProductsMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.products.translate(state.editable!.id, translations);
      })
      .then((response) => response.data)
      .then(async (response) => {
        const product = Product.fromJSON(response);

        await dispatch(ProductsActions.UPDATE_EDITABLE, { translations: product.translations });

        commit(ProductsMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.products.remove(id))
      .then(() => {
        commit(ProductsMutations.UPDATE_INITIAL_EDITABLE, {
          status: ProductStatus.Inactive,
        });
        commit(ProductsMutations.UPDATE_EDITABLE, {
          status: ProductStatus.Inactive,
        });
        commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The product was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-product';
    if (isEdit) {
      commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.products.restore(id))
      .then(() => {
        if (isEdit) {
          commit(ProductsMutations.UPDATE_INITIAL_EDITABLE, {
            status: ProductStatus.Active,
          });
          commit(ProductsMutations.UPDATE_EDITABLE, {
            status: ProductStatus.Active,
          });
          commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(ProductsActions.LOAD_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The product was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(ProductsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  async [ProductsActions.LOAD_ACCESSORIES_LIST]({ commit, dispatch }, filters = {}) {
    commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Loading);

    try {
      const res = await api.accessories.list(filters);
      commit(ProductsMutations.SET_ACCESSORIES_LIST_PAGINATION, Pagination.fromJSON(res.data));
      commit(
        ProductsMutations.SET_ACCESSORIES_LIST,
        res.data.data.map((item) => Accessory.fromJSON(item)),
      );
      commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [ProductsActions.LOAD_ACCESSORIES_TREE]({ commit, dispatch }) {
    commit(ProductsMutations.SET_ACCESSORIES_TREE_STATUS, ListStatus.Loading);

    try {
      const res = await api.accessories.listTree();
      commit(
        ProductsMutations.SET_ACCESSORIES_TREE,
        res.data.map((item) => AccessoryTree.fromJSON(item)),
      );
      commit(ProductsMutations.SET_ACCESSORIES_TREE_STATUS, ListStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(ProductsMutations.SET_ACCESSORIES_TREE_STATUS, ListStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [ProductsActions.LOAD_ACCESSORY]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Loading);

    if (!id) {
      const accessory = new Accessory();
      accessory.status = AccessoryStatus.Active;

      commit(ProductsMutations.SET_ACCESSORY, accessory);
      commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Loaded);

      return Promise.resolve();
    }

    try {
      const response = await api.accessories.get(id);
      commit(ProductsMutations.SET_ACCESSORY, Accessory.fromJSON(response.data));
      commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
      commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Failed);
      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  [ProductsActions.UPDATE_ACCESSORY]({ commit }, changes): Promise<void> {
    commit(ProductsMutations.UPDATE_ACCESSORY, changes);
    return Promise.resolve();
  },

  [ProductsActions.SAVE_ACCESSORY]({ state, commit, dispatch }): Promise<void> {
    let isUpdate: boolean;
    let url: string;
    let successMsg: string;

    return new Promise<void>((resolve, reject) => {
      if (state.accessory) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Saving);

        isUpdate = Boolean(state.accessory!.id);
        url = 'accessory';
        successMsg = i18next.t('A new accessory was added successfully.');

        if (isUpdate) {
          url += `/${state.accessory!.id}`;
          successMsg = i18next.t('The accessory was successfully updated.');
        }
      })
      .then(() => api.accessories.store(state.accessory!.toJSON()))
      .then((response) => response.data)
      .then((response): Promise<Accessory> => {
        const accessory = Accessory.fromJSON(response);

        commit(ProductsMutations.SET_ACCESSORY, accessory);
        commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Saved);

        if (!isUpdate) {
          return new Promise((resolve, reject) => {
            if (!accessory.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-accessory',
                params: {
                  id: String(accessory.id),
                },
              },
              () => resolve(accessory),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(accessory);
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.SAVE_ACCESSORY_TRANSLATIONS]({ state, commit, dispatch }, translations): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.accessory) {
        return resolve();
      }

      reject(new Error('Invalid data!'));
    })
      .then(() => {
        commit(ProductsMutations.SET_ACCESSORY_TRANSLATIONS_STATUS, EditableStatus.Saving);
        return api.accessories.translate(state.accessory!.id, translations);
      })
      .then((response) => response.data)
      .then(async (response: object) => {
        const accessory = Accessory.fromJSON(response);

        await dispatch(ProductsActions.UPDATE_ACCESSORY, { translations: accessory.translations });

        commit(ProductsMutations.SET_ACCESSORY_TRANSLATIONS_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_ACCESSORY_TRANSLATIONS_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.DELETE_ACCESSORY]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-accessory';
    if (isEdit) {
      commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Saving);
    } else {
      commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.accessories.remove(id))
      .then(() => {
        if (isEdit) {
          commit(ProductsMutations.UPDATE_INITIAL_ACCESSORY, {
            status: AccessoryStatus.Inactive,
          });
          commit(ProductsMutations.UPDATE_ACCESSORY, {
            status: AccessoryStatus.Inactive,
          });
          commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Saved);
        } else {
          dispatch(ProductsActions.LOAD_ACCESSORIES_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The accessory was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Failed);
        } else {
          commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.RESTORE_ACCESSORY]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-accessory';
    if (isEdit) {
      commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Saving);
    } else {
      commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => api.accessories.restore(id))
      .then(() => {
        if (isEdit) {
          commit(ProductsMutations.UPDATE_INITIAL_ACCESSORY, {
            status: AccessoryStatus.Active,
          });
          commit(ProductsMutations.UPDATE_ACCESSORY, {
            status: AccessoryStatus.Active,
          });
          commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Saved);
        } else {
          dispatch(ProductsActions.LOAD_ACCESSORIES_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The accessory was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(ProductsMutations.SET_ACCESSORY_STATUS, EditableStatus.Failed);
        } else {
          commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.products.restore(id))))
      .then(() => {
        dispatch(ProductsActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% product was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.products.remove(id))))
      .then(() => {
        dispatch(ProductsActions.LOAD_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% product was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.BULK_RESTORE_ACCESSORY](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map(async (id) => api.accessories.restore(id))))
      .then(() => {
        dispatch(ProductsActions.LOAD_ACCESSORIES_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% accessory was restored successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [ProductsActions.BULK_DELETE_ACCESSORY](
    { commit, dispatch },
    { ids = [] }: { ids: number[] } = { ids: [] },
  ): Promise<void> {
    commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => Promise.all(ids.map((id) => api.accessories.remove(id))))
      .then(() => {
        dispatch(ProductsActions.LOAD_ACCESSORIES_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% accessory was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(ProductsMutations.SET_ACCESSORIES_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
};

export default actions;
