import router from '@/router';
import api from '@/api';
import {
  RootActions,
  ErrorMessages,
  ActionsTree,
  ListStatus,
  EditableStatus,
  EventTypes,
  PublishStatus,
} from '@/store/types';
import { EnhancedAxiosError } from '@/api/utils';

import { LocationsState, LocationsActions, LocationsMutations } from '@/store/modules/locations/types';
import { NotificationsActions } from '@/store/modules/notifications/types';
import Pagination from '@/store/models/pagination';
import Location, { LocationJSON } from './location';
import i18next from '@/i18n';
import { enhanceErrorWithMessage } from '@/utils/errors';

export const actions: ActionsTree<LocationsState, typeof LocationsActions> = {
  [LocationsActions.LOAD_LOCATIONS_LIST]({ commit, dispatch }, options): Promise<void> {
    commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return api.listings
      .list(options)
      .then((response) => {
        commit(LocationsMutations.SET_LIST_PAGINATION, Pagination.fromJSON(response.data));
        commit(
          LocationsMutations.SET_LIST,
          response.data.data.map((item) => Location.fromJSON(item)),
        );
        commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [LocationsActions.LOAD_LOCATIONS_MENU_LIST]({ commit, dispatch, rootGetters }): Promise<void> {
    // Locations menu is shown only to users
    if (!rootGetters['user/profile/isUser']) {
      return Promise.resolve();
    }

    const practiceId = rootGetters['user/profile/practiceId'];

    commit(LocationsMutations.SET_MENU_LIST_STATUS, ListStatus.Loading);
    return api.listings
      .list({ practice_id: practiceId })
      .then((response) => {
        const pagination = Pagination.fromJSON(response.data);
        commit(
          LocationsMutations.SET_MENU_LIST,
          response.data.data.map((item) => Location.fromJSON(item)),
        );
        commit(LocationsMutations.SET_MENU_LIST_TOTAL, pagination.total);
        commit(LocationsMutations.SET_MENU_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(LocationsMutations.SET_MENU_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [LocationsActions.LOAD_PENDING_LIST]({ commit, dispatch, rootGetters }): Promise<void> {
    // Pending list is only shown to users and admins
    if (
      !rootGetters['user/profile/isUser'] &&
      !rootGetters['user/profile/isAdmin'] &&
      !rootGetters['user/profile/isSuperAdmin']
    ) {
      return Promise.resolve();
    }

    let practiceId: number | undefined;
    if (rootGetters['user/profile/isUser']) {
      practiceId = rootGetters['user/profile/practiceId'];
    }

    commit(LocationsMutations.SET_PENDING_LIST_STATUS, ListStatus.Loading);
    return api.listings
      .list({
        practice_id: practiceId,
        status: PublishStatus.Pending,
      })
      .then((response) => {
        const pagination = Pagination.fromJSON(response.data);
        commit(
          LocationsMutations.SET_PENDING_LIST,
          response.data.data.map((item) => Location.fromJSON(item)),
        );
        commit(LocationsMutations.SET_PENDING_LIST_TOTAL, pagination.total);
        commit(LocationsMutations.SET_PENDING_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(LocationsMutations.SET_PENDING_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [LocationsActions.LOAD_PUBLISHED_LIST]({ commit, rootGetters, dispatch }): Promise<void> {
    // Total number of published locations is only shown to users
    if (!rootGetters['user/profile/isUser']) {
      return Promise.resolve();
    }

    const practiceId = rootGetters['user/profile/practiceId'];

    commit(LocationsMutations.SET_PUBLISHED_LIST_STATUS, ListStatus.Loading);
    return api.listings
      .list({
        practice_id: practiceId,
        status: PublishStatus.Published,
      })
      .then((response) => {
        const pagination = Pagination.fromJSON(response.data);
        commit(LocationsMutations.SET_PUBLISHED_LIST_TOTAL, pagination.total);
        commit(LocationsMutations.SET_PUBLISHED_LIST_STATUS, ListStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(LocationsMutations.SET_PUBLISHED_LIST_STATUS, ListStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [LocationsActions.LOAD_LOCATION](
    { commit, dispatch, getters, rootGetters },
    { id, practice_id, revision = 'latest' } = {},
  ): Promise<void> {
    commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Loading);

    let locationPromise: Promise<Location>;
    const isUser = rootGetters['user/profile/isUser'];

    if (!id) {
      const location: Location = new Location();

      if (isUser) {
        location.practiceId = rootGetters['user/profile/practiceId'];
      } else {
        const practiceId = Number(practice_id);
        if (practiceId && Number.isFinite(practiceId)) {
          location.practiceId = practiceId;
        }
      }

      locationPromise = Promise.resolve(location);
    } else {
      locationPromise = api.listings.get(id, revision).then((response) => Location.fromJSON(response.data));
    }

    commit(LocationsMutations.SET_HAS_AUTOSAVE, false);

    return locationPromise
      .then((location: Location) => {
        commit(LocationsMutations.SET_EDITABLE, location);

        return location;
      })
      .then(async (location) => {
        // Load published version of location for review process
        if (!isUser && location.id && location.status === PublishStatus.Pending) {
          const publishedLocation = await api.listings
            .get(location.id, 'published')
            .then((response) => Location.fromJSON(response.data));

          commit(LocationsMutations.SET_QUICK_REVIEW_LOCATION, {
            published: publishedLocation,
            latest: Location.clone(location),
          });

          commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Loaded);
        } else {
          // Rollback any leftovers
          commit(LocationsMutations.SET_QUICK_REVIEW_LOCATION);

          // Check if location has autosaved version
          if (location.id && !location.autosave) {
            return api.listings
              .get(location.id, 'autosave')
              .then((response) => {
                commit(LocationsMutations.SET_AUTOSAVE, Location.fromJSON(response.data));

                if (getters.autosaveHasUnsavedChanges) {
                  commit(LocationsMutations.SET_HAS_AUTOSAVE, true);
                }
              })
              .catch(() => {
                commit(LocationsMutations.SET_AUTOSAVE, Location.clone(location));
              });
          } else {
            commit(LocationsMutations.SET_AUTOSAVE, Location.clone(location));
          }
        }
      })
      .then(() => {
        commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Loaded);
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);
        commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },
  [LocationsActions.SAVE_EDITABLE]({ dispatch, commit, state, rootGetters }): Promise<void> {
    commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);

    if (!state.editable) {
      return Promise.reject(new Error('Internal Error: Editable location not available.'));
    }

    const location: LocationJSON = state.editable.toJSON();
    const isUpdate = Boolean(location.id);

    return dispatch(LocationsActions.AUTOSAVE_STOP)
      .then(() => api.listings.store(location))
      .then((response) => {
        const savedLocation = Location.fromJSON(response.data);
        commit(LocationsMutations.SET_EDITABLE, savedLocation);
        commit(LocationsMutations.SET_AUTOSAVE, undefined);
        commit(LocationsMutations.SET_HAS_AUTOSAVE, false);
        commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);

        if (!isUpdate || router.currentRoute.query.autosaved) {
          return new Promise<Location>((resolve, reject) => {
            if (!savedLocation.id) {
              return reject(new Error('Invalid data.'));
            }

            router.replace(
              {
                name: 'edit-location',
                params: {
                  id: String(savedLocation.id),
                },
              },
              () => resolve(savedLocation),
              (error: any) => reject(error),
            );
          });
        }

        return Promise.resolve(savedLocation);
      })
      .then((savedLocation) => {
        dispatch(LocationsActions.LOAD_LOCATIONS_MENU_LIST);
        dispatch(
          RootActions.DISPATCH_EVENT,
          {
            type: EventTypes.UPDATE_LOCATION,
            eventObj: { location: savedLocation },
          },
          { root: true },
        );

        const isUnderReview =
          !rootGetters['user/profile/isAdmin'] &&
          !rootGetters['user/profile/isSuperAdmin'] &&
          savedLocation.status === PublishStatus.Pending;
        let successMsg = i18next.t('A new location was added successfully.');

        if (isUnderReview) {
          successMsg = i18next.t(
            'The location has been submitted to the AMPS team for review.' +
              " You will be notified when it's published.",
          );
        }

        if (isUpdate) {
          successMsg = i18next.t('The location was successfully updated.');

          if (isUnderReview) {
            successMsg = i18next.t(
              'The location has been submitted to the AMPS team for review.' +
                ' You will be notified when edits are published.',
            );
          }
        }

        return dispatch(
          'notifications/' + (isUnderReview ? NotificationsActions.SHOW_WARNING : NotificationsActions.SHOW_SUCCESS),
          {
            body: successMsg,
          },
          { root: true },
        );
      })
      .catch(async (error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        // Restore back initial status.
        if (state.initialEditable && state.editable) {
          await dispatch(LocationsActions.UPDATE_EDITABLE, {
            status: state.initialEditable.status,
          });
        }

        commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [LocationsActions.AUTOSAVE_DEBOUNCE]({ dispatch, commit, state }): Promise<void> {
    // Clear timeout if exists
    if (state.autosaveTimeout) {
      clearTimeout(state.autosaveTimeout);
    }

    commit(
      LocationsMutations.SET_AUTOSAVE_TIMEOUT,
      setTimeout(() => dispatch(LocationsActions.AUTOSAVE), 2000),
    );

    return Promise.resolve();
  },

  [LocationsActions.AUTOSAVE]({ dispatch, commit, getters, state }): Promise<void> {
    if (!state.editable || !state.editable.id || !getters.autosaveHasUnsavedChanges) {
      return Promise.resolve();
    }

    // If we are autosaving older state, try again in 2 seconds
    if (state.autosaveStatus === EditableStatus.Saving) {
      // Clear timeout if exists
      if (state.autosaveTimeout) {
        clearTimeout(state.autosaveTimeout);
        commit(LocationsMutations.SET_AUTOSAVE_TIMEOUT, undefined);
      }

      if (state.autosaveCancelToken) {
        state.autosaveCancelToken.cancel();

        commit(LocationsMutations.SET_AUTOSAVE_CANCEL_TOKEN, undefined);
      }
    }

    commit(LocationsMutations.SET_AUTOSAVE_STATUS, EditableStatus.Saving);

    const location: LocationJSON = state.editable.toJSON();
    const cancelToken = api.getCancelTokenSource();

    commit(LocationsMutations.SET_AUTOSAVE_CANCEL_TOKEN, cancelToken);
    return api.listings
      .store(location, 'autosave', { cancelToken: cancelToken.token })
      .then((response) => {
        commit(LocationsMutations.SET_AUTOSAVE, Location.fromJSON(response.data));
        commit(LocationsMutations.SET_HAS_AUTOSAVE, false);
        commit(LocationsMutations.SET_AUTOSAVE_STATUS, EditableStatus.Saved);
      })
      .catch((error) => {
        if (api.isCancel(error)) {
          return;
        }

        if (api.isAPIError(error)) {
          return;
        }

        commit(LocationsMutations.SET_AUTOSAVE_STATUS, EditableStatus.Failed);
        dispatch(LocationsActions.AUTOSAVE_DEBOUNCE);
      });
  },

  [LocationsActions.AUTOSAVE_STOP]({ state, commit }): Promise<void> {
    // Clear timeout if exists
    if (state.autosaveTimeout) {
      clearTimeout(state.autosaveTimeout);
      commit(LocationsMutations.SET_AUTOSAVE_TIMEOUT, undefined);
    }

    if (state.autosaveCancelToken) {
      state.autosaveCancelToken.cancel();

      commit(LocationsMutations.SET_AUTOSAVE_CANCEL_TOKEN, undefined);
    }

    return Promise.resolve();
  },

  [LocationsActions.UPDATE_EDITABLE]({ commit, dispatch }, changes): Promise<void> {
    commit(LocationsMutations.UPDATE_EDITABLE, changes);
    dispatch(LocationsActions.AUTOSAVE_DEBOUNCE);
    return Promise.resolve();
  },

  [LocationsActions.DELETE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-location';
    if (isEdit) {
      commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => dispatch(LocationsActions.AUTOSAVE_STOP))
      .then(() => api.listings.remove(id))
      .then(() => {
        if (isEdit) {
          commit(LocationsMutations.UPDATE_INITIAL_EDITABLE, {
            status: PublishStatus.Deleted,
          });
          commit(LocationsMutations.UPDATE_EDITABLE, {
            status: PublishStatus.Deleted,
          });
          commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(LocationsActions.LOAD_LOCATIONS_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() => {
        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The location was deleted successfully.'),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [LocationsActions.RESTORE]({ commit, dispatch }, { id = 0 }: { id?: number } = {}): Promise<void> {
    const isEdit = router.currentRoute.name === 'edit-location';
    if (isEdit) {
      commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Saving);
    } else {
      commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Loading);
    }

    return new Promise<void>((resolve, reject) => {
      if (!id) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => dispatch(LocationsActions.AUTOSAVE_STOP))
      .then(() => api.listings.restore(id))
      .then(() => {
        if (isEdit) {
          commit(LocationsMutations.UPDATE_INITIAL_EDITABLE, {
            status: PublishStatus.Published,
          });
          commit(LocationsMutations.UPDATE_EDITABLE, {
            status: PublishStatus.Published,
          });
          commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Saved);
        } else {
          dispatch(LocationsActions.LOAD_LOCATIONS_LIST, {
            ...router.currentRoute.params,
            ...router.currentRoute.query,
          });
        }
      })
      .then(() =>
        dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('The location was restored successfully.'),
          },
          { root: true },
        ),
      )
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        if (isEdit) {
          commit(LocationsMutations.SET_EDITABLE_STATUS, EditableStatus.Failed);
        } else {
          commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Failed);
        }

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [LocationsActions.SET_IS_PREVIEW_FROM_EDIT_SCREEN]({ commit }): Promise<void> {
    commit(LocationsMutations.SET_IS_PREVIEW_FROM_EDIT_SCREEN);

    return Promise.resolve();
  },

  [LocationsActions.BULK_RESTORE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => dispatch(LocationsActions.AUTOSAVE_STOP))
      .then(() => Promise.all(ids.map(async (id) => api.listings.restore(id))))
      .then(() => {
        dispatch(LocationsActions.LOAD_LOCATIONS_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% location was published successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  [LocationsActions.BULK_DELETE]({ commit, dispatch }, { ids = [] }: { ids: number[] } = { ids: [] }): Promise<void> {
    commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Loading);

    return new Promise<void>((resolve, reject) => {
      if (!ids || !ids.length) {
        return reject(new Error('Invalid data!'));
      }

      resolve();
    })
      .then(() => dispatch(LocationsActions.AUTOSAVE_STOP))
      .then(() => Promise.all(ids.map((id) => api.listings.remove(id))))
      .then(() => {
        dispatch(LocationsActions.LOAD_LOCATIONS_LIST, {
          ...router.currentRoute.params,
          ...router.currentRoute.query,
        });

        return dispatch(
          'notifications/' + NotificationsActions.SHOW_SUCCESS,
          {
            body: i18next.t('%(count)% location was deleted successfully', { count: ids.length }),
          },
          { root: true },
        );
      })
      .catch((error) => {
        enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

        commit(LocationsMutations.SET_LIST_STATUS, ListStatus.Failed);

        return dispatch(RootActions.ERROR, error, { root: true });
      });
  },

  async [LocationsActions.LOAD_LOCATIONS_QUICK_REVIEW]({ commit, dispatch }, { id } = {}): Promise<void> {
    commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Loading);

    try {
      const response = await api.listings.list({
        status: PublishStatus.Pending,
      });
      const pagination = Pagination.fromJSON(response.data);

      if (!pagination.total || !response.data.data || !response.data.data[0]) {
        throw new Error(i18next.t('Nothing to review.'));
      }

      let locationId: number = id;

      if (!locationId) {
        const location = Location.fromJSON(response.data.data[0]);
        locationId = location.id;
      }

      const [published, latest] = await Promise.all([
        api.listings.get(locationId, 'published'),
        api.listings.get(locationId, 'latest'),
      ]).then((resps) => resps.map((res) => Location.fromJSON(res.data)));

      if (latest.status !== PublishStatus.Pending) {
        if (id) {
          router.push({ name: 'locations-quick-review' });
          return Promise.resolve();
        }

        throw new Error(i18next.t('Nothing to review. Selected location is not pending.'));
      }

      commit(LocationsMutations.SET_QUICK_REVIEW_TOTAL, pagination.total);

      commit(LocationsMutations.SET_QUICK_REVIEW_LOCATION, {
        published,
        latest,
      });

      commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Loaded);
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Failed);

      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },

  async [LocationsActions.SAVE_LOCATIONS_QUICK_REVIEW](
    { state, commit, dispatch },
    { approved, explanation }: { approved: boolean; explanation?: string } = {
      approved: false,
    },
  ): Promise<void> {
    commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Saving);

    try {
      if (!state.quickReviewLatestEditable) {
        throw new Error(i18next.t('Location not available.'));
      }

      const location = Location.clone(state.quickReviewLatestEditable);

      if (approved) {
        location.status = PublishStatus.Published;
      } else {
        if (!explanation) {
          throw new Error(i18next.t('Explanation is required.'));
        }

        location.status = PublishStatus.Declined;
        location.explanation = explanation;
      }

      const data = location.toJSON();

      commit(LocationsMutations.SET_QUICK_REVIEW_LOCATION, {
        published: state.quickReviewPublishedEditable,
        latest: location,
      });

      await api.listings.store(data);

      commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Saved);

      if (state.quickReviewTotal < 2) {
        router.push({
          name: 'locations',
          query: { status: String(PublishStatus.Pending) },
        });
      } else if (router.currentRoute.name === 'locations-quick-review' && router.currentRoute.params.id) {
        // If current route is a particular location review then redirect to dynamic quick review route
        router.push({ name: 'locations-quick-review' });
      } else {
        dispatch(LocationsActions.LOAD_LOCATIONS_QUICK_REVIEW);
      }
    } catch (error) {
      enhanceErrorWithMessage(error, ErrorMessages.DATA_LOAD_ERROR);

      commit(LocationsMutations.SET_QUICK_REVIEW_STATUS, EditableStatus.Failed);

      return dispatch(RootActions.ERROR, error, { root: true });
    }
  },
};

export default actions;
