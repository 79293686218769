import { GetterTree } from 'vuex';
import { RootState, ListStatus } from '@/store/types';
import { BeforeAfterImagesState } from './types';
import { createEditableGetters } from '@/store/utils/editable';

const editableGetters = createEditableGetters<BeforeAfterImagesState>('editableStatus', 'editable', 'initialEditable');

export const moduleGetters: GetterTree<BeforeAfterImagesState, RootState> = {
  isLoading: (state) => state.status === ListStatus.Loading,
  isLoaded: (state) => state.status === ListStatus.Loaded,
  isError: (state) => state.status === ListStatus.Failed,
  isEmpty: (state, getters) => getters.isLoaded && !state.list.length,

  /**
   * Edit
   */
  editableIsLoading: editableGetters.isLoading,
  editableIsLoaded: editableGetters.isLoaded,
  editableIsSaving: editableGetters.isSaving,
  editableIsSaved: editableGetters.isSaved,
  editableIsFailed: editableGetters.isFailed,
  editableHasUnsavedChanges: editableGetters.hasUnsavedChanges,
  editablePracticeId: (state) =>
    state.editable && state.editable.practice && state.editable.practice.id ? state.editable.practice.id : 0,
};

export default moduleGetters;
