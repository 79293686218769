
import { mapGetters, mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import domUtils from '@/components/mixins/dom-utils';
import imageSizeMixin from '@/components/mixins/image-size';
import { UserActions } from '@/store/modules/user/types';

export default mixins(imageSizeMixin, domUtils).extend({
  name: 'MainSidebarLayout',
  data() {
    return {
      currentRouteName: '',
      currentRoutePracticeId: 0,
      isShownProducts: false,
      isShownTreatments: false,
      isShownProfile: false,
      isShownReports: false,
      isShownPractice: false,
      isShownLocations: false,
      isShownPractitioners: false,
      isShownUsers: false,
      isShownBeforeAfterImages: false,
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters('user/profile', ['displayName', 'profileImage', 'isSuperAdmin', 'isAdmin']),
    ...mapGetters('practices', ['currentPracticeId']),
    ...mapGetters('locations', {
      locationPracticeId: 'editablePracticeId',
    }),
    ...mapGetters('practitioners', {
      practitionerPracticeId: 'editablePracticeId',
    }),
    ...mapGetters('users', {
      userPracticeId: 'editablePracticeId',
    }),
    ...mapGetters('before-after-images', {
      beforeAfterPracticeId: 'editablePracticeId',
    }),
    sizedProfileImage() {
      return this.sizedUserProfileImage(this.profileImage);
    },
    practiceId() {
      if (['view-practice', 'edit-practice'].indexOf(this.currentRouteName) !== -1) {
        return this.currentPracticeId;
      }

      if (['locations', 'practitioners', 'users', 'before-after-images'].indexOf(this.currentRouteName) !== -1) {
        return this.currentRoutePracticeId;
      }

      if (['edit-location', 'preview-edit-location', 'add-location'].indexOf(this.currentRouteName) !== -1) {
        return this.locationPracticeId;
      }

      if (['edit-practitioner', 'add-practitioner'].indexOf(this.currentRouteName) !== -1) {
        return this.practitionerPracticeId;
      }

      if (['edit-user', 'add-user'].indexOf(this.currentRouteName) !== -1) {
        return this.userPracticeId;
      }
      if (['edit-before-after-images', 'add-before-after-images'].indexOf(this.currentRouteName) !== -1) {
        return this.beforeAfterPracticeId;
      }
      return 0;
    },
    hasPracticeId() {
      return Boolean(this.practiceId);
    },
  },
  methods: {
    ...mapActions('user', {
      logout: UserActions.LOGOUT,
    }),
    toggleSidebarNav(event) {
      if (this.isExpanded) {
        this.isExpanded = false;
        this.isShownProducts = false;
        this.isShownTreatments = false;
        this.isShownProfile = false;
        this.addBodyClass('main-sidebar--contracted');
        this.removeBodyClass('main-sidebar--expanded');
      } else {
        this.removeBodyClass('main-sidebar--contracted');
        this.addBodyClass('main-sidebar--expanded');
        this.isExpanded = true;
      }
    },
    isRoute(routes: string | string[]) {
      const names = (Array.isArray(routes) ? routes : [routes])
        // Cast to string
        .map((val) => String(val))
        // Filter empty strings and invalid values
        .filter((val) => !!val);

      if (!names.length || !this.currentRouteName || names.indexOf(this.currentRouteName) === -1) {
        return false;
      }

      return true;
    },
  },
  watch: {
    $route: {
      // the handler will be called immediately after the start of the observation
      immediate: true,
      handler(to, from) {
        this.removeBodyClass('site-navigation--open');
        this.currentRouteName = to.name || '';

        if (to.query && to.query.practice_id) {
          this.currentRoutePracticeId = Number(to.query.practice_id);
        } else {
          this.currentRoutePracticeId = 0;
        }
      },
    },
  },
  mounted() {
    if (this.isSuperAdmin || this.isAdmin) {
      this.addBodyClass('main-sidebar--contracted');
    }
  },
});
