import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse, Cancel } from 'axios';

import httpClient from '@/api/http-client';
import api from '.';
import { LoginResponse } from './endpoints/auth';
import { addBreadcrumb, Severity } from '@sentry/browser';

export interface EnhancedAxiosError<T = any> extends AxiosError<T> {
  errorMessage?: string;
}

let refreshTokenInterceptor: number | undefined;
let refreshTokenRequestInterceptor: number | undefined;
let refreshPromise: Promise<AxiosResponse<LoginResponse>> | undefined;

/**
 * This ensures that only one refresh token request will be sent
 */
function requestTokenRefresh() {
  if (!refreshPromise) {
    refreshPromise = api.auth
      .refresh({ skip_refresh: true })
      .then((res) => {
        refreshPromise = undefined;
        return res;
      })
      .catch((error) => {
        refreshPromise = undefined;
        throw error;
      });
  }

  return refreshPromise;
}

function removeRefreshTokenInterceptor() {
  if (refreshTokenInterceptor) {
    httpClient.interceptors.response.eject(refreshTokenInterceptor);
    refreshTokenInterceptor = undefined;
  }

  if (refreshTokenRequestInterceptor) {
    httpClient.interceptors.response.eject(refreshTokenRequestInterceptor);
    refreshTokenRequestInterceptor = undefined;
  }
}

function addRefreshTokenInterceptor() {
  removeRefreshTokenInterceptor();

  refreshTokenRequestInterceptor = httpClient.interceptors.request.use(
    (config) => {
      if (
        refreshPromise &&
        // @ts-ignore
        config.skip_refresh !== true
      ) {
        return refreshPromise.then(() => config);
      }

      return Promise.resolve(config);
    },
    (error) => Promise.reject(error),
  );

  refreshTokenInterceptor = httpClient.interceptors.response.use(
    (res) => res,
    async (error: AxiosError) => {
      if (
        // @ts-ignore
        error.config?.skip_refresh === true ||
        error.response?.status !== 401
      ) {
        throw error;
      }

      return requestTokenRefresh()
        .catch((refreshTokenError) => {
          // tslint:disable-next-line: no-console
          console.error(refreshTokenError);

          addBreadcrumb({
            category: 'api-request',
            message: 'API token refresh failed',
            data: {
              ...refreshTokenError,
            },
            level: Severity.Error,
          });

          removeAccessToken();

          window.location = window.location;

          throw refreshTokenError;
        })
        .then(async (res) => {
          const access_token = res.data.access_token;
          setAccessToken(access_token, false);

          if (!(error.config?.headers)) {
              if (!error.config) {
                  throw new Error('Error config is undefined');
              }
              error.config.headers = {} as AxiosRequestHeaders;
          }

          error.config.headers.Authorization = `Bearer ${access_token}`;

          try {
            const orgRes = await httpClient.request({
              ...error.config,
              // @ts-ignore
              skip_refresh: true,
            });
            setAccessToken(access_token);
            return orgRes;
          } catch (orgReqError) {
            addBreadcrumb({
              category: 'api-request',
              message: 'API Request Failed after token refresh',
              data: {
                ...error,
              },
              level: Severity.Error,
            });

            throw orgReqError;
          }
        });
    },
  );
}

export function getAccessToken() {
  // TODO: switch to cookies with backward compatibility
  return localStorage.getItem('user-token');
}

export function setAccessToken(token: string, initRefreshTokenInterceptor = true) {
  httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;

  if (initRefreshTokenInterceptor) {
    addRefreshTokenInterceptor();
  }

  localStorage.setItem('user-token', token);

  // TODO: switch to cookies with backward compatibility
  // Cookies.set(COOKIE_ACCESS_TOKEN_KEY, token, { expires: 1 });
}

export function removeAccessToken() {
  removeRefreshTokenInterceptor();
  delete httpClient.defaults.headers.common.Authorization;
  localStorage.removeItem('user-token');

  // TODO: switch to cookies with backward compatibility
  // Cookies.remove(COOKIE_ACCESS_TOKEN_KEY);
}

export function getCancelTokenSource() {
  return axios.CancelToken.source();
}

export function isCancel(value: any): value is Cancel {
  return axios.isCancel(value);
}

export function isAxiosError(value: any): value is EnhancedAxiosError {
  return axios.isAxiosError(value);
}

export function isAPIError(value: any): value is EnhancedAxiosError<{ errors: any }> {
  if (!isAxiosError(value)) {
    return false;
  }

  if (value.response?.data.errors) {
    return true;
  }

  return false;
}

export function isAPILoginError(value: any): value is EnhancedAxiosError<{ error: string }> {
  if (!isAxiosError(value)) {
    return false;
  }

  if (
    value.response &&
    value.response.status === 401 &&
    typeof value.response.data.error === 'string' &&
    value.response.data.error.length
  ) {
    return true;
  }

  return false;
}
